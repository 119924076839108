import React, {useState} from 'react';
import profileDP from '../assets/profileDP_smaller.png';
import CV from '../downloads/CV.pdf';
import { motion } from "framer-motion"
import { ReactTyped } from "react-typed";
import { BsAmazon, BsGithub, BsLinkedin } from 'react-icons/bs';
import { HiDownload } from 'react-icons/hi';
import { FaArtstation } from 'react-icons/fa';

function Hero() {
    return (
        <section id='top' className='m-5 text-black dark:text-gray-50'
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
        >
            <div className='mt-[10rem] md:mt-[7rem] sm:mt-[4rem] mb-[7vw] w-full text-center items-center flex flex-col justify-center '>
                <motion.div className='p-4 font-medium text-slate-400 sm:p-7 md:p-10 text-1xl sm:text-2xl dark:text-gray-200'
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.125 }}
                >Howdy-do! </motion.div>
                <motion.h1 className='p-5 text-4xl font-bold sm:text-2xl md:text-5xl dark:text-gray-200'
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.15 }}
                >My name is Shawn.</motion.h1>
                <motion.div className='p-4 text-gray-400 sm:p-7 md:p-10 dark:text-gray-300'
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.175 }}
                >
                    <p className='font-light text-1xl sm:text-2xl '> I am a... </p>
                    <ReactTyped className='text-2xl font-light text-gray-800 sm:text-3xl dark:text-gray-300'
                        strings = {["Software Developer", "Web Developer", "Graphic Designer"]}
                        typeSpeed = {40}
                        backSpeed = {50}
                        loop
                    >
                    </ReactTyped>
                    <p className='pt-5 font-light text-1xl sm:text-2xl'> My main focus right now is building Web apps (Front-end).</p>
                    <div className='z-20 flex flex-col justify-center font-light lg:flex-row text-1xl sm:text-2xl'> 
                        <p className='text-wrap md:text-nowrap'>This website is built with&nbsp;</p>
                        <a href="https://react.dev/" target='_blank' rel="noopener noreferrer" className="text-black underline duration-200 ease-in-out hover:text-slate-400 hover:font-bold active:text-xl dark:text-gray-100">reactJS</a> 
                        <p className='text-wrap md:text-nowrap'>&nbsp;and styled with&nbsp;</p>
                        <a href="https://tailwindcss.com/" target='_blank' rel="noopener noreferrer" className="text-black underline duration-200 ease-in-out hover:text-slate-400 hover:font-bold active:text-xl dark:text-gray-100">Tailwind</a>
                        <p>.</p> 
                        </div>
                </motion.div>
                <motion.img src={profileDP} className='justify-center object-cover object-center gap-10 m-5 duration-100 ease-in-out origin-center scale-150 border-4 border-b border-white rounded-full shadow-xl bg-clip-border w-[25rem] h-[25rem] hover:animate-wiggle place-content-center' 
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                />
                <motion.div className='flex flex-col items-center justify-center object-center text-center align-middle sm:flex-row'
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.225 }}
                >
                    <a href={CV} download className="flex items-center gap-2 p-4 mx-5 mt-10 font-extrabold text-white duration-500 ease-in-out rounded-full shadow-lg cursor-pointer dark:text-slate-800 group text-nowrap bg-cyan-500 hover:bg-cyan-400 active:scale-90"> 
                        <p>Download CV {" "}</p>
                        <HiDownload className='duration-500 ease-in-out -translate-y-0.5 transition group-hover:animate-bounce' />
                    </a>
                    <a href='https://www.linkedin.com/in/shawn-tham-5b1670141' target='_blank' rel="noopener noreferrer" className="p-5 mt-5 font-extrabold duration-500 ease-in-out bg-white rounded-full shadow-lg cursor-pointer sm:mt-10 sm:ml-2 w-fit hover:bg-gray-200 active:scale-90 dark:bg-slate-700 dark:hover:bg-slate-500"> <BsLinkedin/>
                    </a>
                    <a href='https://www.github.com/shorntheshrimp?tab=repositories' target='_blank' rel="noopener noreferrer" className="p-5 mt-2 font-extrabold duration-500 ease-in-out bg-white rounded-full shadow-lg cursor-pointer sm:mt-10 sm:ml-2 w-fit hover:bg-gray-200 active:scale-90 dark:bg-slate-700 dark:hover:bg-slate-500"> <BsGithub/>
                    </a>
                    <a href='https://www.artstation.com/shorntheshrimp' target='_blank' rel="noopener noreferrer" className="p-5 mt-2 font-extrabold duration-500 ease-in-out bg-white rounded-full shadow-lg cursor-pointer sm:mt-10 sm:ml-2 w-fit hover:bg-gray-200 active:scale-90 dark:bg-slate-700 dark:hover:bg-slate-500"> <FaArtstation/>
                    </a>
                </motion.div>
            </div>
        </section>
    )
}

export default Hero
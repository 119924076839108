import React from 'react'
import SectionHeading from './SectionHeading.tsx'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import "react-vertical-timeline-component/style.min.css"
import { experiencesData } from '../lib/data.ts'
import { useTheme } from '../context/ThemeContextProvider.tsx'

export default function Experience() {

    const { theme } = useTheme();

  return (
    <section id="experience" className='pb-[5rem] text-gray-500 dark:text-gray-100 mx-3'>
        <SectionHeading>Experience</SectionHeading>
        <VerticalTimeline lineColor= {theme === "light" ? "rgb(229 231 235 / 1)" : "rgb(55 65 81 / 1)"} className='-z-10'>
            {
                experiencesData.map((experience, index) => (
                    <React.Fragment key={index}>
                        <VerticalTimelineElement 
                            contentStyle={{
                                background: theme === "light" ? "white" : "rgb(51 65 85 / 1)",
                                boxShadow: "none",
                                border: theme === "light" ? "1px solid rgba(0, 0, 0, 0.05)" : "1px solid rgba(0.5, 0.5, 0.5, 0.05)",
                                textAlign: "left",
                                padding: "1.3rem 2rem",
                                minWidth: "12rem",
                                textWrap: 'wrap',
                                wordWrap: 'normal',
                                zIndex: '-10',
                            }}
                            contentArrowStyle={{
                                borderRight: "0.4rem solid #9ca3af"
                            }}
                            date= {experience.year}
                            icon= {experience.icon}
                            iconStyle={{
                                background: theme === "light" ? "white" : "rgb(55 65 81 / 1)",
                                fontSize: "1.5rem",
                            }}
                        >
                            <h3 className='font-semibold !mt-0'> {experience.title} </h3>
                            <p className='!italic !font-thin !m-0'> {experience.location} </p>
                            <p className='!mt-1 !pt-5 !font-normal whitespace-pre-line'> {experience.description} </p>
                        </VerticalTimelineElement>
                    </React.Fragment>
                ))
            }
        </VerticalTimeline>
    </section>
  )
}

import React from 'react';

type SectionHeadingProps = {
    children: React.ReactNode;
};

function SectionHeading({ children } : SectionHeadingProps) {
  return (
    <h1 className='mt-2 mb-2 text-4xl font-bold leading-8 text-black dark:text-gray-200 pb-14'>
        { children }
    </h1>
  )
}

export default SectionHeading
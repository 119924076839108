import React, {useRef, useState} from 'react';
import SectionHeading from './SectionHeading.tsx'
import { projectsData } from '../lib/data.ts'
import { motion, useScroll, useTransform } from 'framer-motion';

function Projects() {
  return (
    <section id='projects' className='-z-10'>
        <SectionHeading>
            My Projects
        </SectionHeading>

        <div className='inline-flex flex-col items-center align-middle'>
            {
                projectsData.map((project, index) => (
                    <React.Fragment key={index}>
                        <Project {...project} />
                    </React.Fragment>
                ))
            }
        </div>
    </section>
  )
}

type projectProps = (typeof projectsData)[number];

function Project({title, description, year, tags, githublink, imageURL}: projectProps) {
    const ref = useRef<HTMLDivElement>(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["0 1", "1.05 1"]
    });
    const scaleYProgress = useTransform( scrollYProgress, [0, 1], [0.7, 1] );

    return (
        <motion.div 
        ref={ref}
        style={{
            scale: scaleYProgress,
            opacity: scrollYProgress,
        }}>
        <section className="group relative flex flex-col p-5 mx-3 overflow-hidden text-left text-black bg-gray-100 dark:bg-slate-700 dark:border-slate-400 dark:text-gray-100 border rounded-2xl h-fit mb-20 min-w xl:w-[45rem] lg:w-[42rem] sm:md:w-[32rem] hover:bg-gray-200 duration-500 ease-in-out">
            <div className='max-w-[25rem] z-10'>
                <h3 className='text-xl font-bold'> {title} </h3>
                <p className='italic font-thin'> {year} </p>
                <p className='drop-shadow-md'> {description} </p>
                <a href={githublink} target='_blank' rel="noopener noreferrer" className='mt-10 underline underline-offset-2'> Github link (source code and more images!) </a>
                <ul className='flex flex-wrap gap-4 mt-10 text-sm'> 
                    {tags.map((tag, index) => (
                        <li key={index} className='px-3 py-1 text-white bg-gray-700 rounded-full dark:bg-slate-900'> {tag} </li>
                    ))} 
                </ul>
            </div>
            <img src={imageURL} alt='Personal projects' className='absolute h-[25rem] left-[25rem] origin-bottom-left rounded-t-lg shadow-xl group-hover:left-[23rem] group-hover:-rotate-6 duration-500 ease-in-out lg:left-[34rem] lg:group-hover:left-[32rem] lg:group-hover:-rotate-4'/>
        </section>
        </motion.div>
    )
}

export default Projects
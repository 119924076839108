import React from "react";
import DIYwhere from "../assets/DIYwhere.png"
import untitledgame from "../assets/puzzlegame.png"
import badmintoneer from "../assets/badmintoneer.png"
import cartbite from "../assets/cartbite.png"
import graphicwlaptop from "../assets/laptop-with-graphic-design-3d.png";
import bookandpencil from "../assets/book-and-pencil-education.png"
import highschoolboy from "../assets/character-high-school-boy.png"
import { LuGraduationCap } from "react-icons/lu";
import { CgWorkAlt } from "react-icons/cg";

export const aboutData = [
    {
        description: "When I first started working in 2015 after serving my National service, my career for the next 4 years was rooted in the graphic and games design space.\
        I was working in a SME which largely centered around VR projects within the professional/tertiary education and training sector e.g. construction, millitary, medical etc.",
        image: graphicwlaptop
    },
    {
        description: "My role consisted of developing wireframes, UI/UX, 3D/2D animations and promotional materials e.g. videos, banners etc and clients liaising. Working closely with software developers, I always kept a growing interest in web/software development. \
        Eventually with some encouragement and guidance from my co-workers, I started to pick up web and application development in work as well.",
        image: highschoolboy
    },
    {
        description: "Around the 4th year mark of my graphic designer career, I decided to pursue a full-time education in software development. I enrolled in Monash University Melbourne middle of 2019 and graduated end of 2022.\
        \n\n For my final year project, I delivered and deployed a PHP based web project with my team as Tech lead and was awarded the prestigious Wong Swee Soon award for the best project in the cohort. This lead to an opportunity\
        to be part of the teaching staff, so I decided to stay within academia as a teaching associate, mainly as a tech mentor. The learning outcomes of my units focuses on professional agile web development; from early stages of\
        requirements gathering to developing and deploying a full live project to clients in the industry.\
        \n\n Since then, I have also taken the role of a QA engineer in Monash eSolutions to expand my skillset but I am currently looking to get back into more problem-solving coding oriented work i.e. web/software development.",
        image: bookandpencil
    }
] as const

export const projectsData = [
    {
        title: "DIYwhere",
        description:"DIYwhere is an online platform that allows private designers to sell their cuztomized goods. This website is created with CakePHP.",
        year: "2023",
        githublink: "https://github.com/shorntheshrimp/DIYwhere_website_cakePHP",
        tags: ["cakePHP", "PHP", "HTML5", "CSS", "JS", "Bootstrap", "mySQL", "Git", "Agile Framework", "SLDC", "Stripe payment", "phpMyAdmin DBMS", "API"],
        imageURL: DIYwhere
    },
    {
        title: "The Untitled Puzzle Game",
        description:"The Untitled Puzzle Game is a 3D single-player Puzzle game developed with C++ via Unreal Engine 4.26.",
        year: "2022",
        githublink: "https://github.com/shorntheshrimp/UntitledPuzzleGame_UE462",
        tags: ["C++", "Unreal Engine", "OOP", "Blueprints", "UID", "Game development", "AI behaviour"],
        imageURL: untitledgame
    },
    {
        title: "CartBite",
        description:"CartBite is an online B2C platform that serves as a bridge between local farmers and a diverse range of consumers.",
        year: "2022",
        githublink: "https://github.com/shorntheshrimp/cartbite_vanillaHTML_CSS",
        tags: ["HTML", "CSS"],
        imageURL: cartbite
    },
    {
        title: "Badmintoneer",
        description:"Badmintoneer is an app to assist in training the fundamental skills of a badminton player, developed for iOS.",
        year: "2022",
        githublink: "https://github.com/shorntheshrimp/Badmintoneer_IOS_App",
        tags: ["Swift", "xCode", "Live chat", "Firebase", "API"],
        imageURL: badmintoneer
    }
] as const;

export const skillsData = [
    "HTML",
    "CSS",
    "Javascript",
    "ReactJS",
    "Next.js",
    "Node.js",
    "Tailwind",
    "Git",
    "PHP",
    "CakePHP",
    "Bootstrap",
    "mySQL",
    "Firebase",
    "Swift",
    "C++",
    "Unreal Engine",
    "C#",
    "Unity",
    "Python",
    "Robot Framework",
    "Test Automation",
    "Jira",
    "Figma",
    "Invision",
    "Adobe Photoshop",
    "Adobe Illustrator",
    "Adobe After effects",
    "Adobe Premiere",
    "Autodesk Maya",
] as const;

export const experiencesData = [
    {
        title: "Quality Assurance Engineer",
        location: "Monash University eSolutions, Melbourne",
        description: "Test new/ongoing complex web-based features for Monash University ELMS 4.1 website.\n\
        \n● Write, review, maintain, execute and automate test cases via Robot framework that improved testing process and coverage by 18%\
        \n● Conducted comprehensive tests on features to be release on live Monash ELMS server prior to release via white box, black box, regression, usability, functional, and acceptance testing methods\
        \n● Collaborate with major stake holders to identify and document critical system defects and ensure quality standards were met\
        \n● Tech Stack: PHP, Python, HTML/CSS",
        icon: React.createElement(CgWorkAlt),
        year: "\xa02023 - current"
    },
    {
        title: "FIT Teaching associate",
        location: "Faculty of IT, Monash University, Melbourne",
        description: "Conduct classes and provide direct support and guidance to students as tech mentor.\n\
        \n● SDLC based teaching on how to build a full-stack web application with CakePHP 4 and hosting on experimental and live servers within 12 weeks o Guide and train students on best coding practices, network security (pen testing, data encryption, SSL certificates, 2FA)\
        \n● Teach students API integration and building RESTful APIs o Fixed bugs during development to ensure each website is industry standard in functionality and usability\
        \n● Deployed more than 20 functional websites on live client servers\
        \n● Facilitate an Agile based team collaboration and task tracking with Trello to all relevant stakeholders\
        \n● Tech Stack: PHP, CakePHP 4, JS, HTML, CSS, mySQL",
        icon: React.createElement(CgWorkAlt),
        year: "\xa02022 - 2023"
    },
    {
        title: "Web developer",
        location: "Monash University, Melbourne",
        description: "Built and deploy a PoC mobile-responsive B2B eCommerse website for an economics Phd researcher during my Final Year Project.\n\
        \n● CMS for customized users with data analytics for buyer data \
        \n● CC Payment implementation via stripe API \
        \n● Wong Swee Soon Award for best website",
        icon: React.createElement(LuGraduationCap),
        year: "\xa02022 - 2022"
    },
    {
        title: "Web developer, UX designer, Project Coordinator",
        location: "Bukit Merah, Singapore",
        description: "Develop web hosted and tailored web applications within the tertiary education, professional and government sector.\n\
        \n● Developed, modified and customized websites based on wireframes and client requests \
        \n● Ensure that all deliverables and web pages adhere closely to WCAG standard to promote disabled userbase usability \
        \n● Created original UI assets with Adobe Photoshop and Illustrator \
        \n● Identified, troubleshooted and communicated project risks and pre-emptively eliminated scope delays to relevant stakeholders \
        \n● Tech-stack: PHP, HTML/CSS, JS",
        icon: React.createElement(LuGraduationCap),
        year: "\xa02015 - 2018"
    }
] as const;
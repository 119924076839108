import React from 'react';
// import './App.css';
import './index.css';
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import { BrowserRouter as Router, Route} from "react-router-dom";
import TScarousel from "./components/TScarousel";
import Sectiondivider from './components/Sectiondivider';
import About from './components/About';
import Projects from './components/Projects.tsx';
import Skills from './components/Skills.tsx'
import Experience from './components/Experience.js';
import Contactme from './components/Contactme.js';
import Footer from './components/Footer.js';
import ThemeContextProvider from './context/ThemeContextProvider.tsx';

function App() {
  return (
      <div className="flex flex-col justify-center text-center App sm:max-[12rem] dark:bg-gray-900 dark:text-gray-50 dark:opacity-90 transition-colors">
        
        <ThemeContextProvider>

          <Navbar/>
          <Hero/>
          <Sectiondivider/>
          <About/>
          <Sectiondivider/>
          <Projects/>
          <Sectiondivider/>
          <Skills/>
          <Sectiondivider/>
          <Experience/>
          <Sectiondivider/>    
          <Contactme/>
          <Footer/>

        </ThemeContextProvider>
      </div>
  );
}

export default App;

import React, {useState} from 'react'
import '../index.css'
import Logo from '../assets/Logo.png'
import { motion } from "framer-motion"
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Themeswitch from './Themeswitch.tsx';

function Navbar() {
    const [nav, setNav] = useState(true)

    function handleNav()
    {
        setNav(!nav);
    }

    return (
      <div className="main-body min-w-10 ">
        <div className="relative h-0 flex top-16 xl:h-[4vw] lg:h-[7vw] md:h-[10vw] sm:h-[14vw] duration-500 ease-in-out">
          <div className="absolute inset-y-0 w-1/3 left-[1vw] xl:left-[33vw] lg:top-[5vw] lg:left-[15vw] md:left-[10rem] sm:left-[5rem] -z-10">
            <div className="grid grid-cols-2 grid-rows-3 h-[32rem] w-[45rem]">
              <div className="row-start-2 row-span-2 col-span-1 bgleftcircle bg-[#ffb8b5] dark:bg-[#e4817e] rounded-full blur-[20rem] -z-10" />
              <div className="row-start-1 row-span-2 col-span-1 bgrightcircle bg-[#d5d1fa] dark:bg-[#554e88] rounded-full blur-[10rem] -z-10" />
            </div>
          </div>

          <div className="navbar flex flex-col justify-between items-center max-w-[1240px] px-4 mx-auto text-sky-100 ">
            <motion.div className="fixed w-[42vw] h-10 p-10 rounded-full bg-white dark:bg-slate-800 dark:bg-opacity-70 flex justify-between items-center origin-center space-x-0 bg-opacity-90 shadow-lg backdrop-blur-sm z-50"
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <div className="flex-none w-5 duration-500 ease-in-out hover:w-7 hover:rotate-180">
                <img src={Logo} className="w-full"></img>
              </div>
              <ul className="flex min-w-0 text-sm text-black duration-500 ease-in-out origin-right scale-0 xl:scale-100 dark:text-gray-50">
                <li className="flex self-center px-3 duration-500 ease-in-out text-nowrap hover:text-base hover:text-cyan-500 active:underline">
                  <HashLink to="#top"> Home </HashLink>
                </li>
                <li className="flex self-center px-3 duration-500 ease-in-out text-nowrap hover:text-base hover:text-cyan-500">
                  <HashLink to="#about"> About me </HashLink>
                </li>
                <li className="flex self-center px-3 duration-500 ease-in-out text-nowrap hover:text-base hover:text-cyan-500">
                  <HashLink to="#projects"> Projects </HashLink>
                </li>
                <li className="flex self-center px-3 duration-500 ease-in-out text-nowrap hover:text-base hover:text-cyan-500">
                  <HashLink to="#skills"> Skills </HashLink>
                </li>
                <li className="p-4 font-extrabold text-white duration-500 ease-in-out rounded-full dark:text-slate-800 text-nowrap ml-7 bg-cyan-500 hover:text-sm hover:p-5">
                  <HashLink to="#contactme"> Contact me! </HashLink>
                </li>
              </ul>
              <div
                className="text-black duration-500 ease-in-out scale-100 xl:hidden xl:scale-0 hover:text-teal-500 hover:cursor-pointer dark:text-slate-100"
                onClick={handleNav}
              >
                {nav ? (
                  <AiOutlineMenu size={20} />
                ) : (
                  <AiOutlineClose size={20} />
                )}
              </div>
            </motion.div>
          </div>
        </div>
        <div
            className={
              nav
                ? "fixed -top-96 lg:right-[28vw] md:right-[26vw] sm:right-[20vw] opacity-0 ease-in-out duration-500 z-50" : "fixed lg:right-[28vw] md:right-[26vw] top-40 ease-in-out duration-500"
            }
          >
            <div className="collapse-navbar origin-top xl:scale-0 h-full ease-in-out duration-500 max-w-[1240px] md:px-11 p-0 mx-auto text-zinc-700 dark:text-zinc-200">
              <div className="w-screen md:w-96">
                <ul className="p-4 text-center uppercase bg-white shadow-lg md:text-right rounded-xl bg-opacity-90 backdrop-blur-sm dark:bg-slate-800 dark:bg-opacity-95">
                  <li className="p-4 pl-0 transition-all border-b md:pl-52 border-b-gray-500 hover:text-lg hover:underline hover:underline-offset-7">
                   <HashLink to="#top"> Home </HashLink>
                  </li>
                  <li className="p-4 pl-0 transition-all border-b md:pl-52 border-b-gray-500 hover:text-lg hover:underline hover:underline-offset-7">
                    <HashLink to="#about"> About me </HashLink>
                  </li>
                  <li className="p-4 pl-0 transition-all border-b md:pl-52 border-b-gray-500 hover:text-lg hover:underline hover:underline-offset-7">
                    <HashLink to="#projects"> Projects </HashLink>
                  </li>
                  <li className="p-4 pl-0 transition-all border-b md:pl-52 border-b-gray-500 hover:text-lg hover:underline hover:underline-offset-7">
                    <HashLink to="#skills"> Skills </HashLink>
                  </li>
                  <li className="p-4 pl-0 transition-all border-b md:pl-52 border-b-gray-500 hover:text-lg hover:underline hover:underline-offset-7">
                  <HashLink to="#contactme"> Contact </HashLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Themeswitch/>
      </div>
    );
}

export default Navbar
import React from 'react'
import { BsMoon, BsSun } from 'react-icons/bs'
import { useTheme } from '../context/ThemeContextProvider.tsx';

export default function Themeswitch() {
    const { theme, toggleTheme } = useTheme();
    
  return (
    <button 
    onClick={toggleTheme}
    className='fixed bottom-5 right-5 bg-white dark:bg-slate-700 w-[3rem] h-[3rem] bg-opacity-75 backdrop-blur-[0.5rem] border border-white dark:border-slate-500 border-opacity-40 shadow-lg rounded-full flex justify-center items-center hover:scale-[1.1] transition-all'>
        { theme === "light" ? <BsSun/> : <BsMoon/> }
    </button>
  )
}


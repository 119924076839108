import React from 'react'
import SectionHeading from './SectionHeading.tsx'
import { skillsData } from '../lib/data.ts'
import { animate, delay, motion } from 'framer-motion'

const fadeInAnimationVariants = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: ( index:number ) => ({
      opacity: 100,
      y: 0,
      transition: {
          delay: 0.05 * index
    }
  })
}

export default function Skills() {
  return (
    <section id='skills' className='flex flex-col items-center px-10 text-center mb-28'>
      <SectionHeading>
        Skills
      </SectionHeading>
      <ul className='flex flex-wrap max-w-[52rem] mx-auto px-auto justify-center gap-2 align-middle'>
          { skillsData.map(( skill, index ) => (
            <motion.div
            variants={ fadeInAnimationVariants }
            initial="initial"
            whileInView="animate"
            viewport={{
              once: true
            }}
            custom={ index }>
              <li 
              key={ index } 
              className='px-3 py-1 transition duration-300 ease-in-out bg-white border rounded-lg cursor-pointer dark:bg-slate-700 border-slate-200 dark:border-slate-400 text-nowrap hover:scale-110'> { skill } </li> 
              </motion.div>
          )) }
      </ul>
    </section>
  )
}

import React from 'react';
import Navbar from "../components/Navbar";
import highschoolboy from "../assets/character-high-school-boy.png"
import {
    AiFillAliwangwang,
    AiFillCaretRight,
    AiFillContacts,
    AiFillUsb,
    AiOutlineMail,
    AiOutlineMessage
} from "react-icons/ai";
import {BsAirplaneFill} from "react-icons/bs";

function Contact() {
    return (
        <div>
            <Navbar/>
            <div className='max-w-md w-full mx-auto p-6 mt-[7vw] bg-slate-700 rounded-lg text-white'>
                <h2 className='text-3xl text-center font-bold mb-6'>Contact me!</h2>
                <form action="">
                    <div className='mb-4'>
                        <label className='block mb-4'> Name </label>
                        <input placeholder='john...' required type='text' className='w-full px-3 py-2 border rounded-lg bg-slate-700 focus:bg-slate-600'></input>
                    </div>
                    <div className='mb-4'>
                        <label className='block mb-4'> Email </label>
                        <input placeholder='john@example.com...' required type='text' className='w-full px-3 py-2 border rounded-lg bg-slate-700 focus:bg-slate-600'></input>
                    </div>
                    <div className='mb-4'>
                        <label className='block mb-4'> Message </label>
                        <textarea rows='4' placeholder="what's on your mind?" required type='text' className='w-full px-3 py-2 border rounded-lg bg-slate-700 focus:bg-slate-600'></textarea>
                    </div>
                    <div className='mb-4 text-center'>
                        <button type='submit' className='bg-cyan-500 text-[#282c34] p-3 pr-8 pl-8 text-center items-center inline-flex font-bold rounded-full' >
                            <span className='pr-3'>Email</span>
                            <AiOutlineMail className='w-6 h-5'/>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Contact
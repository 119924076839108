import React from 'react';
import Navbar from "./Navbar";
import { motion } from "framer-motion"
import SectionHeading from './SectionHeading.tsx';
import { aboutData } from '../lib/data.ts';

function About() {
    return (
        <section id='about' className='h-auto p-10 text-base text-black dark:text-gray-200 xl:text-lg -z-10'>
            <motion.div className='font-bold text-white mt-14'
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
            >
                <SectionHeading>ABOUT ME</SectionHeading>
            </motion.div>
            <motion.div className='flex flex-col items-center justify-center mx-auto space-x-0 p-2 mt-[1vw] xl:w-[70rem] lg:w-[50rem] md:w-[45rem] md:pb-10 md:flex-row'
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.325 }}
            >
                <motion.p 
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.325 }}
                className='pb-10 pr-0 text-center md:text-right md:pr-14 md:pb-0'> 
                    { aboutData[0].description }
                </motion.p>
                <motion.img
                initial={{ opacity: 0, x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.325 }}
                src={ aboutData[0].image } className='pl-0 w-[14rem] max-w-[14rem] drop-shadow-2xl lg:w-[24rem] md:border-l-2 md:max-w-[24rem] md:pl-12'/>
            </motion.div>
            <motion.div className='flex flex-col-reverse items-center justify-center mx-auto space-x-0 p-2 mt-[1vw] xl:w-[70rem] lg:w-[50rem] md:w-[45rem] md:pb-10 md:flex-row'
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.35 }}
            >
                <motion.img
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.325 }}
                src= { aboutData[1].image }
                className='scale-90 pr-0 w-[14rem] max-w-[14rem] drop-shadow-2xl lg:w-[24rem] md:border-r-2 md:max-w-[24rem] md:pr-12' />
                <motion.p 
                initial={{ opacity: 0, x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.325 }} className='pb-10 pl-0 text-center md:text-left md:pl-14 md:pb-0'>
                    { aboutData[1].description }
                </motion.p>
            </motion.div>
            <motion.div className='flex flex-col items-center justify-center mx-auto space-x-0 p-2 mt-[1vw] xl:w-[70rem] lg:w-[50rem] md:w-[45rem] md:pb-10'
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.375 }}
            >
                <motion.p 
                initial={{ opacity: 0, y: -100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.325 }}
                 className='pl-2 text-center'>
                    { aboutData[2].description }
                 </motion.p>
                <motion.img
                initial={{ opacity: 0,y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.325 }}
                src={ aboutData[2].image } className='mx-auto lg:w-[32rem] drop-shadow-2xl w-[22rem]'/>
            </motion.div>
        </section>
    )
}

export default About
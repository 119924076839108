import React from 'react'

function Footer() {
  return (
    <footer className='px-4 mb-10 text-center text-gray-400'>
        <small className='block mb-2 text-xs'>
            &copy; 2024 Shawn Tham. All rights reserved
        </small>
        <p className='text-xs'>
            <span>Website info: </span>
            Built with React.js, Typescript, Tailwind CSS, Framer motion, EmailJS, Vercel hosting.
        </p>
    </footer>
  )
}

export default Footer
import React from 'react'
import { motion } from "framer-motion"

export default function Sectiondivider() {
  return (
    <div className='flex justify-center'>
        <motion.div className="w-1 h-16 mb-24 bg-gray-200 rounded-full dark:bg-gray-700"
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.125 }}
        />
    </div>
  )
}

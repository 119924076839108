import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, createBrowserRouter, RouterProvider} from 'react-router-dom';

import './index.css'
import App from './App';
import About from "./routes/About";
import Portfolio from "./routes/Portfolio";
import Contact from "./routes/Contact";

const router = createBrowserRouter([
    { path: "/", element: <App /> },
    // { path: "/routes/About", element: <About /> },
    // { path: "/routes/Portfolio", element: <Portfolio /> },
    // { path: "/routes/Contact", element: <Contact /> },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Navbar from "./Navbar";
import {
    AiFillAliwangwang,
    AiFillCaretRight,
    AiFillContacts,
    AiFillUsb,
    AiOutlineMail,
    AiOutlineMessage
} from "react-icons/ai";
import {BsAirplaneFill} from "react-icons/bs";
import SectionHeading from './SectionHeading.tsx';
import { EMAILJS_PUBLIC_KEY, EMAILJS_TEMPLATE_ID, EMAILJS_SERVICE_ID } from '../lib/keys.js'
import { HiInformationCircle } from 'react-icons/hi';

export default function Contactme() {

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
    
      emailjs
        .sendForm(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, form.current, {
          publicKey: EMAILJS_PUBLIC_KEY,
        })
        .then(
          (result) => {
            console.log(result.text);
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };

    return (
        <section id='contactme'>
            <div className='md:w-[50rem] w-full p-6 mx-auto rounded-lg text-slate-500 dark:text-slate-100'>
                <SectionHeading>Contact me!</SectionHeading>
                
                <form ref={form} onSubmit={sendEmail} action="">
                    <div className='mb-4'>
                        <label className='block mb-4'> Name </label>
                        <input name='user_name' placeholder='john...' required type='text' className='w-full px-3 py-2 bg-white border rounded-lg dark:bg-slate-700 dark:focus:bg-slate-600 focus:bg-slate-100 focus:outline-none'></input>
                    </div>
                    <div className='mb-4'>
                        <label className='block mb-4'> Email </label>
                        <input name="user_email" placeholder='john@example.com...' required type='text' className='w-full px-3 py-2 bg-white border rounded-lg dark:bg-slate-700 dark:focus:bg-slate-600 focus:bg-slate-100 focus:outline-none'></input>
                    </div>
                    <div className='mb-4'>
                        <label className='block mb-4'> Message </label>
                        <textarea name="message" rows='7' placeholder="what's on your mind?" required type='text' className='w-full px-3 py-2 bg-white border rounded-lg dark:bg-slate-700 dark:focus:bg-slate-600 focus:bg-slate-100 focus:outline-none'></textarea>
                    </div>
                    <div className='text-sm text-gray-500 dark:text-gray-400'>
                        <span className='flex flex-wrap items-center justify-center gap-2 text-center border-b border-slate-500'>
                            <HiInformationCircle/>
                            <p>Email might not send with VPN enabled </p>                      
                        </span>
                        <span className='flex flex-wrap items-center justify-center gap-1 text-center'>
                            <p>My Email:</p>
                            <a href={'mailto:shawntham@outlook.com'} className='underline'> shawntham@outlook.com </a>
                        </span>
                    </div>
                    <div className='my-4 mb-4 text-center'>
                        <input type='submit' value="Send Email" className='inline-flex items-center p-3 pl-8 pr-8 font-bold text-center text-white rounded-full cursor-pointer dark:text-slate-800 bg-cyan-500' />
                            {/* <span className='pr-3 '>Email</span>
                            <AiOutlineMail className='w-6 h-5 '/>
                        </button> */}
                    </div>
                </form>
            </div>
        </section>
    )
}
